import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { Provider } from 'react-redux'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import store from './redux/store'
import {
  setServiceWorkerInit,
  setServiceWorkerUpdate
} from './redux/actions'

const _store = store()
ReactDOM.render(
  <Provider store={_store}>
    <App />
  </Provider>
  ,
  document.getElementById('root')
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (process.env.NODE_ENV !== 'production' && 'serviceWorker' in navigator) {
// if (!isPlatform('cordova')) {
  serviceWorkerRegistration.register({
    onSuccess: registration => {
      _store.dispatch(setServiceWorkerInit(registration))
    },
    onUpdate: registration => {
      _store.dispatch(setServiceWorkerUpdate(registration))
    }
  })
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
