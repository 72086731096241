import React, { useState } from 'react'
import {
  IonGrid,
  IonRow,
  IonContent,
  useIonViewDidEnter,
  useIonViewWillLeave
} from '@ionic/react'
import MuteButton from '../player-parts/MuteButton'
import PlayButton from '../player-parts/PlayButton'
import NowPlaying from '../player-parts/NowPlaying'
import RefreshButton from '../player-parts/RefreshButton'
import StationButton from '../player-parts/StationButton'
import { stationsArr as stations } from '../../../config/stations'
import { setRootMetaTags } from '../../../services/metatags'
import './styles.scss'

const Player = () => {
  const [isVisible, setIsVisible] = useState(false)

  useIonViewDidEnter(() => {
    setIsVisible(true)
    setRootMetaTags({
      title: 'Disfruta de la música de Yok Radio - Reproductor de Radio Online'
    })
  })

  useIonViewWillLeave(() => {
    setIsVisible(false)
  })

  if (isVisible === false) return null

  return (
    <IonContent
      fullscreen
    >
      <div
        className='full-player-wrapper'
      >

        <div className='full-player'>
          <div className='wrapper'>
            <MuteButton />
            <PlayButton />
            <RefreshButton />
            <IonGrid>
              <IonRow>
                <NowPlaying />
              </IonRow>
              <IonRow className='ion-justify-content-around'>
                {stations.map((station) => (
                  <StationButton station={station} key={station.id} />
                ))}
              </IonRow>
            </IonGrid>
          </div>
        </div>

      </div>
    </IonContent>
  )
}

export default Player
