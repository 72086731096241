import React from 'react'
import { IonPage } from '@ionic/react'
import Header from '../components/core/Header'
import NetworkStatus from '../components/core/NetworkStatus'

const PlayerLayout = ({ children }) => {
  return (
    <IonPage>
      <Header />
      {children}
      <NetworkStatus />
    </IonPage>
  )
}

export default PlayerLayout
