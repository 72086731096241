import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  setPlayerLoading,
  setPlayerPlaying,
  setPlayerPause,
  setSound,
  playerLoading,
  playerPause,
  playerPlaying,
  setTrack
} from '../../../../redux/actions'
import {
  IonButton,
  IonSpinner
} from '@ionic/react'
import YokIcon from '../../../common/YokIcon'
import { setEvent } from '../../../../services/analytics'
import './styles.scss'

const clearStr = (str) => {
  let output = str || ''
  output = output.toLowerCase()
  output = output.replace(/\s/g, '-')
  return output
}

const generateTrack = (content) => {
  return {
    id: `${typeof content.content_type !== 'undefined' ? `${clearStr(content.content_type)}-` : ''}${content.url_title}-${content.id_content || content.id}`,
    status: 'off-line',
    icon_off: '',
    icon_on: '',
    windable: content.windable || false,
    source_url: content.audio_file,
    type: content.audio_file_type,
    length: '',
    stats_url: content.stats_url || '',
    artist: content.content_type || '',
    title: content.title,
    artwork: [
      { src: (content.photo || content.banner), sizes: `${content.banner_width || 0}x${content.banner_height || 0}` }
    ]
  }
}

const ContentButton = ({ content, large, size }) => {
  const track = useSelector(state => state.track)
  const playerStatus = useSelector(state => state.playerStatus)
  const sound = useSelector(state => state.sound)
  const player = useSelector(state => state.player)
  const muted = useSelector(state => state.muted)
  const dispatch = useDispatch()
  const [current, setCurrent] = useState(false)

  const _setEvent = (category, action, label) => {
    setEvent({
      category: category || 'Botón de Reproducción de Contenido',
      action: action || '-',
      label: label || track.id
    })
  }

  const isBuffering = (buffering) => {
    if (buffering === 1) {
      dispatch(setPlayerLoading())
    } else {
      dispatch(setPlayerPlaying())
    }
  }

  const onDataError = (error) => {
    console.info('Error on data', error)
  }

  const onError = (errorCode, description) => {
    console.info('Error playing Error code:' + errorCode)
    console.info('Error playing Error message:', description)
  }

  const playTrack = () => {
    if (current === false) {
      const _track = generateTrack(content)
      if (sound !== null) {
        sound.destruct()
      }
      const _sound = player.createSound({
        id: _track.id,
        url: _track.source_url,
        volume: 100,
        multiShot: false,
        playbackRate: 1,
        stream: true,
        onbufferchange: isBuffering,
        ondataerror: onDataError,
        onerror: onError
      })
      if (muted) {
        _sound.mute()
      }
      dispatch(setSound(_sound))
      dispatch(setPlayerPlaying())
      dispatch(setTrack(_track))
      _sound.play()
      _setEvent('Botón de Reproducción de Contenido', 'Reproducir audio de Contenido', _track.id)
    }
  }

  const play = () => {
    sound.play()
    dispatch(setPlayerPlaying())
    _setEvent('Botón de Reproducción de Contenido', 'Reanudar Reproducción', track.id)
  }

  const pause = () => {
    sound.pause()
    dispatch(setPlayerPause())
    _setEvent('Botón de Reproducción de Contenido', 'Pausar Reproducción', track.id)
  }

  useEffect(() => {
    if (track !== null && typeof content !== 'undefined') {
      if (track.id === generateTrack(content).id) {
        setCurrent(true)
      }
    }
    return () => {
      setCurrent(false)
    }
  }, [track, content])

  if (large) {
    return (
      <div className='player-content-button large'>
        {!current &&
          <IonButton
            onClick={playTrack}
          >
            Reproducir <YokIcon name='play-b' />
          </IonButton>}
        {current && playerStatus === playerPlaying &&
          <IonButton
            onClick={pause}
          >
            Pausar <YokIcon name='pause_circle_outline' />
          </IonButton>}
        {current && playerStatus === playerPause &&
          <IonButton
            onClick={play}
          >
            Reproducir <YokIcon name='play-b' />
          </IonButton>}
        {current && playerStatus === playerLoading &&
          <IonButton>
            <IonSpinner name='crescent' />
          </IonButton>}
      </div>
    )
  } else {
    return (
      <div className={`player-content-button ${size || ''}`}>
        {!current &&
          <IonButton
            onClick={playTrack}
          >
            <YokIcon name='ra-play' />
          </IonButton>}

        {current && playerStatus === playerPlaying &&
          <IonButton
            onClick={pause}
          >
            <YokIcon name='ra-pause' />
          </IonButton>}
        {current && playerStatus === playerPause &&
          <IonButton
            onClick={play}
          >
            <YokIcon name='ra-play' />
          </IonButton>}
        {current && playerStatus === playerLoading &&
          <IonButton>
            <IonSpinner name='crescent' />
          </IonButton>}
      </div>
    )
  }
}

export default ContentButton
