import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setYrUrban,
  setYrClassics,
  setYrTropical,
  setYrRomantic,
  setLiveVideo,
  setRequestingStreams
} from '../../../../redux/actions'
import {
  IonButton,
  IonIcon
} from '@ionic/react'
import {
  reloadOutline
} from 'ionicons/icons'
import { stations } from '../../../../config/stations'
import { liveVideo, liveVideoId } from '../../../../config/live'
import { getStreamResource } from '../../../../services/api/streaming'
import './styles.scss'

const RefreshButton = (props) => {
  const dispatch = useDispatch()
  const requestingStreams = useSelector(state => state.requestingStreams)
  const _stations = Object.keys(stations).join(',')

  const getLiveResources = () => {
    dispatch(setRequestingStreams(true))
    const params = {
      stream_id: `${_stations},${liveVideoId}`
    }
    getStreamResource(params)
      .then((response) => {
        response.forEach((res) => {
          switch (res.stream_id) {
            case 'yr_urban':
              dispatch(setYrUrban(Object.assign(stations.yr_urban, res)))
              break
            case 'yr_classics':
              dispatch(setYrClassics(Object.assign(stations.yr_classics, res)))
              break
            case 'yr_tropical':
              dispatch(setYrTropical(Object.assign(stations.yr_tropical, res)))
              break
            case 'yr_romantic':
              dispatch(setYrRomantic(Object.assign(stations.yr_romantic, res)))
              break
            case 'radio-live-video':
              dispatch(setLiveVideo(Object.assign(liveVideo, res)))
              break
            default:
              return res
          }
        })
        dispatch(setRequestingStreams(false))
      })
      .catch(() => {
        dispatch(setRequestingStreams(false))
        return false
      })
  }

  return (
    <IonButton
      className='player-refresh-button'
      onClick={getLiveResources}
      disabled={requestingStreams}
    >
      <IonIcon size='48' icon={reloadOutline} />
    </IonButton>
  )
}

export default RefreshButton
