import React from 'react'
import {
  useSelector,
  useDispatch
} from 'react-redux'
import {
  IonButton,
  IonRange,
  IonLabel
} from '@ionic/react'
import YokIcon from '../../../common/YokIcon'
import {
  setTrackProgress
} from '../../../../redux/actions'
import './styles.scss'

const PlaybackBar = () => {
  const sound = useSelector(state => state.sound)
  const track = useSelector(state => state.track)
  const trackCurrentTime = useSelector(state => state.trackCurrentTime)
  const trackTimeLeft = useSelector(state => state.trackTimeLeft)
  const trackProgress = useSelector(state => state.trackProgress)
  const trackDuration = useSelector(state => state.trackDuration)
  const dispatch = useDispatch()

  const changeProgress = (e) => {
    if (e.currentTarget.classList.contains('range-pressed')) {
      sound.setPosition(parseInt(e.detail.value))
      dispatch(setTrackProgress(sound.position))
    }
  }

  const windBack = () => {
    sound.setPosition(sound.position > 30000 ? sound.position - 30000 : 0)
  }

  const windFordwar = () => {
    if (sound.position + 30000 < sound.duration) {
      sound.setPosition(sound.position + 30000)
    }
  }

  if (track === null || sound === null) {
    return (
      <div className='playback-bar none' />
    )
  } else {
    if (track.windable === false) {
      return (
        <div className='playback-bar none' />
      )
    } else {
      return (
        <div className='playback-bar'>
          <IonButton
            className='wind-back'
            onClick={windBack}
            slot='start'
            fill='clear'
          >
            <YokIcon name='replay_30' />
          </IonButton>
          <IonLabel className='time-elapsed'>{trackCurrentTime}</IonLabel>
          <IonRange
            value={trackProgress}
            onIonChange={changeProgress}
            min={0}
            max={trackDuration}
          />
          <IonLabel className='time-left'>{trackTimeLeft}</IonLabel>
          <IonButton
            className='wind-fordward'
            onClick={windFordwar}
            fill='clear'
          >
            <YokIcon name='forward_30' />
          </IonButton>
        </div>
      )
    }
  }
}

export default PlaybackBar
