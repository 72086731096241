import { appBasePath } from './app'

export const stations = {
  yr_urban: {
    id: 'yr_urban',
    status: 'off-line',
    icon_off: 'icon-ra-urban-disconected',
    icon_on: 'icon-ra-urban',
    windable: false,
    source_url: '',
    stats_url: '',
    type: '',
    length: '0',
    artist: 'Yok Radio',
    title: 'Trendy',
    public_name: 'Trendy',
    description: 'Escucha las canciones más sonadas del momento, en algunos géneros como: reggaetón, pop, R&B y más.',
    artwork: [
      { src: appBasePath + '/assets/artwork/urban/artwork-96.jpg', sizes: '96x96', type: 'image/jpeg' },
      { src: appBasePath + '/assets/artwork/urban/artwork-128.jpg', sizes: '128x128', type: 'image/jpeg' },
      { src: appBasePath + '/assets/artwork/urban/artwork-192.jpg', sizes: '192x192', type: 'image/jpeg' },
      { src: appBasePath + '/assets/artwork/urban/artwork-256.jpg', sizes: '256x256', type: 'image/jpeg' },
      { src: appBasePath + '/assets/artwork/urban/artwork-384.jpg', sizes: '384x384', type: 'image/jpeg' },
      { src: appBasePath + '/assets/artwork/urban/artwork-512.jpg', sizes: '512x512', type: 'image/jpeg' }
    ],
    default: false
  },
  yr_classics: {
    id: 'yr_classics',
    status: 'off-line',
    icon_off: 'icon-ra-guitar-disconected',
    icon_on: 'icon-ra-guitar',
    windable: false,
    source_url: '',
    stats_url: '',
    type: '',
    length: '0',
    artist: 'Yok Radio',
    title: 'Legend',
    public_name: 'Legend',
    description: 'Revive las canciones en inglés que nunca pasarán de moda y que ahora son un clásico en géneros como: Rock, Soul, Disco, Pop y muchos más.',
    artwork: [
      { src: appBasePath + '/assets/artwork/classics/artwork-96.jpg', sizes: '96x96', type: 'image/jpeg' },
      { src: appBasePath + '/assets/artwork/classics/artwork-128.jpg', sizes: '128x128', type: 'image/jpeg' },
      { src: appBasePath + '/assets/artwork/classics/artwork-192.jpg', sizes: '192x192', type: 'image/jpeg' },
      { src: appBasePath + '/assets/artwork/classics/artwork-256.jpg', sizes: '256x256', type: 'image/jpeg' },
      { src: appBasePath + '/assets/artwork/classics/artwork-384.jpg', sizes: '384x384', type: 'image/jpeg' },
      { src: appBasePath + '/assets/artwork/classics/artwork-512.jpg', sizes: '512x512', type: 'image/jpeg' }
    ],
    default: false
  },
  yr_tropical: {
    id: 'yr_tropical',
    status: 'off-line',
    icon_off: 'icon-ra-tropical-disconected',
    icon_on: 'icon-ra-tropical',
    windable: false,
    source_url: '',
    type: '',
    length: '0',
    stats_url: '',
    artist: 'Yok Radio',
    title: 'Latin Sound',
    public_name: 'Latin Sound',
    description: 'Todos los ritmos autóctonos de Latinoamérica, los ritmos que corren por tus venas, entre estos se encuentran: Salsa, Merengue, Bachata, Cumbia, entre otros.',
    artwork: [
      { src: appBasePath + '/assets/artwork/tropical/artwork-96.jpg', sizes: '96x96', type: 'image/jpeg' },
      { src: appBasePath + '/assets/artwork/tropical/artwork-128.jpg', sizes: '128x128', type: 'image/jpeg' },
      { src: appBasePath + '/assets/artwork/tropical/artwork-192.jpg', sizes: '192x192', type: 'image/jpeg' },
      { src: appBasePath + '/assets/artwork/tropical/artwork-256.jpg', sizes: '256x256', type: 'image/jpeg' },
      { src: appBasePath + '/assets/artwork/tropical/artwork-384.jpg', sizes: '384x384', type: 'image/jpeg' },
      { src: appBasePath + '/assets/artwork/tropical/artwork-512.jpg', sizes: '512x512', type: 'image/jpeg' }
    ],
    default: false
  },
  yr_romantic: {
    id: 'yr_romantic',
    status: 'off-line',
    icon_off: 'icon-ra-love-disconected',
    icon_on: 'icon-ra-love',
    windable: false,
    source_url: '',
    stats_url: '',
    type: '',
    length: '0',
    artist: 'Yok Radio',
    title: 'Beat',
    public_name: 'Beat',
    description: 'Canciones que te llegan al alma, que tocan las fibras de tu corazón, aquí encuentras los artistas más representativos de grandes géneros como: Baladas en español e inglés, Pop romántico y música plancha',
    artwork: [
      { src: appBasePath + '/assets/artwork/romantic/artwork-96.jpg', sizes: '96x96', type: 'image/jpeg' },
      { src: appBasePath + '/assets/artwork/romantic/artwork-128.jpg', sizes: '128x128', type: 'image/jpeg' },
      { src: appBasePath + '/assets/artwork/romantic/artwork-192.jpg', sizes: '192x192', type: 'image/jpeg' },
      { src: appBasePath + '/assets/artwork/romantic/artwork-256.jpg', sizes: '256x256', type: 'image/jpeg' },
      { src: appBasePath + '/assets/artwork/romantic/artwork-384.jpg', sizes: '384x384', type: 'image/jpeg' },
      { src: appBasePath + '/assets/artwork/romantic/artwork-512.jpg', sizes: '512x512', type: 'image/jpeg' }
    ],
    default: false
  },
  yr_live: {
    id: 'yr_live',
    status: 'off-line',
    icon_off: 'yr-microphone-disconected',
    icon_on: 'yr-microphone',
    windable: false,
    source_url: '',
    stats_url: '',
    type: '',
    length: '0',
    artist: 'Yok Radio',
    title: 'Live',
    public_name: 'Live',
    description: 'Canciones que te llegan al alma, que tocan las fibras de tu corazón, aquí encuentras los artistas más representativos de grandes géneros como: Baladas en español e inglés, Pop romántico y música plancha',
    artwork: [
      { src: appBasePath + '/assets/artwork/romantic/artwork-96.jpg', sizes: '96x96', type: 'image/jpeg' },
      { src: appBasePath + '/assets/artwork/romantic/artwork-128.jpg', sizes: '128x128', type: 'image/jpeg' },
      { src: appBasePath + '/assets/artwork/romantic/artwork-192.jpg', sizes: '192x192', type: 'image/jpeg' },
      { src: appBasePath + '/assets/artwork/romantic/artwork-256.jpg', sizes: '256x256', type: 'image/jpeg' },
      { src: appBasePath + '/assets/artwork/romantic/artwork-384.jpg', sizes: '384x384', type: 'image/jpeg' },
      { src: appBasePath + '/assets/artwork/romantic/artwork-512.jpg', sizes: '512x512', type: 'image/jpeg' }
    ],
    default: true
  }
}

export const stationsArr = [
  stations.yr_tropical,
  stations.yr_urban,
  stations.yr_live,
  stations.yr_classics,
  stations.yr_romantic
]

export const defaultStation = stations.yr_urban

export const urlPublicNames = {
  latinsound: 'yr_tropical',
  trendy: 'yr_urban',
  legend: 'yr_classics',
  beat: 'yr_romantic',
  live: 'yr_live'
}
