import React from 'react'
import { Link } from 'react-router-dom'
import {
  IonMenu,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuToggle,
  IonButton
} from '@ionic/react'
import {
  close,
  logoInstagram,
  logoSoundcloud,
  logoYoutube,
  logoWhatsapp
} from 'ionicons/icons'
import YokIcon from '../../common/YokIcon'
import './styles.scss'

const Navbar = () => {
  return (
    <IonMenu
      side='end'
      contentId='main'
      type='overlay'
      className='main-menu'
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='end'>
            <IonMenuToggle>
              <IonButton>
                <IonIcon size='48' icon={close} />
              </IonButton>
            </IonMenuToggle>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList className='categories'>
          <IonItem lines='none'>
            <IonLabel>
              <IonMenuToggle>
                <Link
                  to='/'
                  aria-label='Ir a la página principal'
                >
                  Inicio
                </Link>
              </IonMenuToggle>
            </IonLabel>
          </IonItem>
        </IonList>
        <section className='buttons-container'>
          <IonButton
            className='rounded-button'
            href='https://api.whatsapp.com/send?phone=50672852947'
            target='_blank'
            rel='noopener noreferrer'
          >
            <IonIcon size='48' icon={logoWhatsapp} />
          </IonButton>
          <IonButton
            className='rounded-button'
            href='https://www.facebook.com/yokradio'
            target='_blank'
            rel='noopener noreferrer'
          >
            <YokIcon name='facebook' />
          </IonButton>
          <IonButton
            className='rounded-button'
            href='https://www.instagram.com/yokradio/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <IonIcon size='48' icon={logoInstagram} />
          </IonButton>
          <IonButton
            className='rounded-button'
            href='https://soundcloud.com/user-136511888'
            target='_blank'
            rel='noopener noreferrer'
          >
            <IonIcon size='48' icon={logoSoundcloud} />
          </IonButton>
          <IonButton
            className='rounded-button'
            href='https://www.youtube.com/channel/UCe3D81tqEbYFbBZPY3m_LQg'
            target='_blank'
            rel='noopener noreferrer'
          >
            <IonIcon size='48' icon={logoYoutube} />
          </IonButton>
        </section>
      </IonContent>
    </IonMenu>
  )
}

export default Navbar
