import React, { useState, useEffect } from 'react'
import { useStatus } from '@ionic/react-hooks/network'
import {
  IonButton,
  IonIcon
} from '@ionic/react'
import {
  warning
} from 'ionicons/icons'
import './styles.scss'

const NetworkStatus = (props) => {
  const [showNotice, setShowNotice] = useState(false)
  const { networkStatus } = useStatus()

  const hide = () => {
    setShowNotice(false)
  }

  useEffect(() => {
    if (typeof networkStatus !== 'undefined') {
      if (networkStatus.connected === false) {
        setShowNotice(true)
      } else {
        setShowNotice(false)
      }
    }
    return () => {
      setShowNotice(false)
    }
  }, [networkStatus])

  if (showNotice) {
    return (
      <div
        className='network-status'
        onClick={hide}
      >
        <IonButton>
          <IonIcon icon={warning} />
          Sin conexión a internet
        </IonButton>
      </div>
    )
  } else {
    return (<></>)
  }
}

export default NetworkStatus
