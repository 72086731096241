import React from 'react'
import { useSelector } from 'react-redux'
import {
  playerIdle
} from '../../../redux/actions'
import MuteButton from '../../../components/core/player-parts/MuteButton'
import PlayButton from '../../../components/core/player-parts/PlayButton'
import NowPlaying from '../../../components/core/player-parts/NowPlaying'
import PlaybackBar from '../../../components/core/player-parts/PlaybackBar'
import './styles.scss'

const PlaylistPlayer = () => {
  const playerStatus = useSelector((state) => state.playerStatus)
  return (
    <div className={`playlist-player ${playerStatus !== playerIdle ? 'active' : ''}`}>
      <div className='wrapper'>
        <MuteButton />
        <PlayButton skipDefault />
        <section className='nowplaying-wrapper'>
          <NowPlaying />
        </section>
        <section className='playback-wrapper'>
          <PlaybackBar />
        </section>
      </div>
    </div>
  )
}

export default PlaylistPlayer
