import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  setPlayerLoading,
  setPlayerPlaying,
  setSound,
  setTrack
} from '../../../../redux/actions'
import {
  IonButton
} from '@ionic/react'
import YokIcon from '../../../common/YokIcon'
import { setEvent } from '../../../../services/analytics'
import './styles.scss'

const StationButton = ({ station }) => {
  const track = useSelector(state => state.track)
  const sound = useSelector(state => state.sound)
  const player = useSelector(state => state.player)
  const stationState = useSelector(state => state[station.stream_id])
  const muted = useSelector(state => state.muted)
  const dispatch = useDispatch()
  const [current, setCurrent] = useState(false)
  const onLineStatus = 'on-line'

  const _setEvent = (category, action, label) => {
    setEvent({
      category: category || 'Botón de Estación',
      action: action || 'Reproducir Estación',
      label: label || stationState.public_name
    })
  }

  const isBuffering = (buffering) => {
    if (buffering === 1) {
      dispatch(setPlayerLoading())
    } else {
      dispatch(setPlayerPlaying())
    }
  }

  const onDataError = (error) => {
    console.info('Error on data', error)
  }

  const onError = (errorCode, description) => {
    console.info('Error playing Error code:' + errorCode)
    console.info('Error playing Error message:', description)
  }

  const playStation = () => {
    if (stationState.status === onLineStatus && current === false) {
      if (sound !== null) {
        sound.destruct()
      }
      const _sound = player.createSound({
        id: stationState.stream_id,
        url: stationState.src,
        volume: 100,
        multiShot: false,
        playbackRate: 1,
        stream: true,
        onbufferchange: isBuffering,
        ondataerror: onDataError,
        onerror: onError
      })
      if (muted) {
        _sound.mute()
      }
      dispatch(setSound(_sound))
      dispatch(setPlayerPlaying())
      dispatch(setTrack(stationState))
      _sound.play()
      _setEvent('Botón de Estación', 'Reproducir Estación', stationState.public_name)
    }
  }

  useEffect(() => {
    if (track !== null && typeof stationState !== 'undefined') {
      if (track.id === stationState.id && stationState.status === 'on-line') {
        setCurrent(true)
      }
    }
    return () => {
      setCurrent(false)
    }
  }, [track, stationState])

  return (
    <IonButton
      className={current ? 'player-station-button active' : 'player-station-button'}
      aria-label={`Reproducir estación de radio ${station.public_name || ''}`}
      onClick={playStation}
    >
      {stationState?.status === onLineStatus && <YokIcon name={station.icon_on.replace('icon-', '')} />}
      {stationState?.status !== onLineStatus && <YokIcon name={station.icon_off.replace('icon-', '')} />}
    </IonButton>
  )
}

export default StationButton
