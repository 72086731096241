import {
  SET_NOTIFICATION,
  SET_SERVICE_WORKER_INIT,
  SET_SERVICE_WORKER_UPDATE,
  SET_GA,
  SET_TRACK,
  SET_DEFAULT_TRACK,
  SET_PLAYING,
  SET_PLAYER,
  SET_MUTED,
  SET_SOUND,
  SET_NOW_PLAYING,
  SET_PLAYER_STATUS,
  SET_TRACK_CURRENT_TIME,
  SET_TRACK_TIME_LEFT,
  SET_TRACK_PROGRESS,
  SET_TRACK_DURATION,
  playerIdle,
  SET_YR_URBAN,
  SET_YR_CLASSICS,
  SET_YR_TROPICAL,
  SET_YR_ROMANTIC,
  SET_YR_LIVE,
  SET_LIVE_VIDEO,
  SET_REQUESTING_STREAMS,
  SET_SHOW_RETURN_BUTTON
} from './actions'

import {
  stations
} from '../config/stations'

import {
  liveVideo
} from '../config/live'

export const initialState = {
  notification: null,
  serviceWorkerInit: false,
  serviceWorkerUpdate: false,
  ga: null,
  player: null,
  muted: false,
  playing: false,
  playerStatus: playerIdle,
  sound: null,
  track: null,
  defaultTrack: null,
  nowPlaying: {
    artist: 'Yok Radio',
    title: 'Online Radio'
  },
  trackProgress: 0,
  trackDuration: 0,
  trackTimeLeft: '00:00',
  trackCurrentTime: '00:00',
  yr_urban: stations.yr_urban,
  yr_classics: stations.yr_classics,
  yr_tropical: stations.yr_tropical,
  yr_romantic: stations.yr_romantic,
  yr_live: stations.yr_live,
  liveVideo: liveVideo,
  requestingStreams: false,
  showReturnButton: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATION:
      return {
        ...state,
        notification: action.notification
      }

    case SET_SERVICE_WORKER_INIT:
      return {
        ...state,
        serviceWorkerInit: action.serviceWorkerInit
      }

    case SET_SERVICE_WORKER_UPDATE:
      return {
        ...state,
        serviceWorkerUpdate: action.serviceWorkerUpdate
      }

    case SET_GA:
      return {
        ...state,
        ga: action.ga
      }

    case SET_PLAYER:
      return {
        ...state,
        player: action.player
      }

    case SET_TRACK:
      return {
        ...state,
        track: action.track
      }

    case SET_DEFAULT_TRACK:
      return {
        ...state,
        track: action.track
      }

    case SET_PLAYING:
      return {
        ...state,
        playing: action.playing
      }

    case SET_MUTED:
      return {
        ...state,
        muted: action.muted
      }

    case SET_SOUND:
      return {
        ...state,
        sound: action.sound
      }

    case SET_NOW_PLAYING:
      return {
        ...state,
        nowPlaying: action.nowPlaying
      }

    case SET_PLAYER_STATUS:
      return {
        ...state,
        playerStatus: action.playerStatus
      }

    case SET_YR_URBAN:
      return {
        ...state,
        yr_urban: action.yr_urban
      }

    case SET_YR_CLASSICS:
      return {
        ...state,
        yr_classics: action.yr_classics
      }

    case SET_YR_TROPICAL:
      return {
        ...state,
        yr_tropical: action.yr_tropical
      }

    case SET_YR_ROMANTIC:
      return {
        ...state,
        yr_romantic: action.yr_romantic
      }

    case SET_YR_LIVE:
      return {
        ...state,
        yr_live: action.yr_live
      }

    case SET_LIVE_VIDEO:
      return {
        ...state,
        liveVideo: action.liveVideo
      }

    case SET_REQUESTING_STREAMS:
      return {
        ...state,
        requestingStreams: action.requestingStreams
      }

    case SET_TRACK_CURRENT_TIME:
      return {
        ...state,
        trackCurrentTime: action.trackCurrentTime
      }

    case SET_TRACK_TIME_LEFT:
      return {
        ...state,
        trackTimeLeft: action.trackTimeLeft
      }

    case SET_TRACK_PROGRESS:
      return {
        ...state,
        trackProgress: action.trackProgress
      }

    case SET_TRACK_DURATION:
      return {
        ...state,
        trackDuration: action.trackDuration
      }

    case SET_SHOW_RETURN_BUTTON:
      return {
        ...state,
        showReturnButton: action.showReturnButton
      }
    default:
      return state
  }
}

export default reducer
