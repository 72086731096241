import { createStore } from 'redux'
import reducer from './reducer'

let store
if (process.env.NODE_ENV !== 'production') {
  store = () => {
    return {
      ...createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
    }
  }
} else {
  store = () => {
    return {
      ...createStore(reducer)
    }
  }
}
export default store
