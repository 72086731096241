import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setMuted } from '../../../../redux/actions'
import { IonButton } from '@ionic/react'
import YokIcon from '../../../common/YokIcon'
import { setEvent } from '../../../../services/analytics'
import './styles.scss'

const MuteButton = (props) => {
  const muted = useSelector(state => state.muted)
  const track = useSelector(state => state.track)
  const sound = useSelector(state => state.sound)
  const dispatch = useDispatch()

  const _setEvent = (category, action) => {
    if (track !== null) {
      setEvent({
        category: category || 'Botón de Mute',
        action: action || '-',
        label: track.id || '-'
      })
    } else {
      setEvent({
        category: category || 'Botón de Mute',
        action: action || '-',
        label: '-'
      })
    }
  }

  const toogleMute = () => {
    if (sound === null) {
      dispatch(setMuted(!muted))
    } else {
      if (sound.muted === true) {
        sound.unmute()
        _setEvent('Botón de Mute', 'Desmutear Reproducción')
      } else {
        sound.mute()
        _setEvent('Botón de Mute', 'Mutear Reproducción')
      }
      dispatch(setMuted(sound.muted))
    }
  }
  return (
    <IonButton className='player-mute-button' onClick={toogleMute}>
      <YokIcon name={muted ? 'volume_off' : 'volume_up'} />
    </IonButton>
  )
}

export default MuteButton
