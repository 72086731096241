import React from 'react'
import { Link } from 'react-router-dom'
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton
} from '@ionic/react'
import YokIcon from '../../common/YokIcon'
import ReturnButton from '../ReturnButton'
import './styles.scss'

const Header = () => {
  return (
    <IonHeader className='main-header'>
      <IonToolbar className='main-toolbar'>

        <ReturnButton />

        <Link
          className='brand-logo'
          to='/'
          aria-label='Ir a la página'
        >
          <YokIcon name='yok-hat' />
        </Link>

        <IonButtons slot='end'>
          <IonMenuButton />
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  )
}

export default Header
