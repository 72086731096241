export const appId = 5

export const appVersion = 1

export const appBasePath = process.env.NODE_ENV === 'production' ? 'https://services.yokradio.com' : 'https://services.yokradio.com'

export const gaTagId = process.env.NODE_ENV === 'production' ? 'G-LB72KRMW1K' : 'G-5WDVZRQ8EQ'

export const fbAppId = process.env.NODE_ENV === 'production' ? '176257497885359' : '176257497885359'

export const fbPixelId = process.env.NODE_ENV === 'production' ? '568388307490038' : '568388307490038' // no usado en el proyecyo

export const corsProxy = 'https://stark-cove-81248.herokuapp.com/'
