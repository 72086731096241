import React, { useEffect } from 'react'
import {
  isPlatform
} from '@ionic/react'
import {
  useSelector,
  useDispatch
} from 'react-redux'
import {
  setNowPlaying
} from '../../../../redux/actions'
import { getStreamStats } from '../../../../services/api/streaming'
import {
  setTitleMetaTag
} from '../../../../services/metatags'

const NowPlayingUpdater = () => {
  const track = useSelector(state => state.track)
  const dispatch = useDispatch()
  var storedData = {
    title: '',
    artist: ''
  }

  const _getStreamStats = () => {
    getStreamStats(track.stats_url)
      .then((response) => {
        const stats = response.data
        const currentStats = {}
        if (typeof stats.icestats !== 'undefined') {
          if (typeof stats.icestats.source !== 'undefined') {
            const splitedTitle = stats.icestats.source.title.split('-')
            currentStats.title = typeof splitedTitle[1] !== 'undefined' ? splitedTitle[1].trim() : ''
            currentStats.artist = typeof splitedTitle[0] !== 'undefined' ? splitedTitle[0].trim() : ''
            if ((storedData.title !== currentStats.title || storedData.artist !== currentStats.artist) && track.stats_url !== '') {
              storedData = {
                title: currentStats.title,
                artist: currentStats.artist
              }
              dispatch(setNowPlaying(currentStats))
              if (isPlatform('cordova')) {
                setTitleMetaTag(`${track.artist} - ${track.title}`)
              }
            }
          }
        }
      })
      .catch((error) => {
        console.error(`Request error: ${error.message}`)
      })
  }

  const updateStats = () => {
    if (track.stats_url === '') {
      if ((storedData.title !== track.title || storedData.artist !== track.artist)) {
        storedData = {
          title: track.title,
          artist: track.artist
        }
        dispatch(setNowPlaying({
          artist: track.artist,
          title: track.title
        }))
        if (isPlatform('cordova')) {
          setTitleMetaTag(`${track.artist} - ${track.title}`)
        }
      }
    }
    if (track.stats_url !== '') {
      _getStreamStats()
    }
  }

  useEffect(() => {
    var interval
    if (track !== null) {
      updateStats()
      if (typeof interval === 'undefined') {
        interval = setInterval(updateStats, 15000)
      }
    }
    return () => {
      if (typeof interval !== 'undefined') {
        clearInterval(interval)
      }
    }
  }, [track])

  return (<></>)
}

export default NowPlayingUpdater
