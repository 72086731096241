import { appBasePath } from './app'

export const radioCategories = [
  {
    category: 'Podcast',
    category_alias: 'podcast',
    category_icon: '',
    id_category: '27',
    id_section: '4',
    section: 'Radio',
    section_alias: 'radio',
    section_icon: 'icon-radio1',
    reel: appBasePath + '/assets/images/reel_podcast.jpg',
    reelMobile: appBasePath + '/assets/images/reel_podcast_mobile.jpg'
  },
  {
    category: 'Especiales',
    category_alias: 'especiales',
    category_icon: '',
    id_category: '32',
    id_section: '4',
    section: 'Radio',
    section_alias: 'radio',
    section_icon: 'icon-radio1',
    reel: appBasePath + '/assets/images/reel_specials.jpg',
    reelMobile: appBasePath + '/assets/images/reel_specials_mobile.jpg'
  },
  {
    category: 'Playlist',
    category_alias: 'playlist',
    category_icon: '',
    id_category: '34',
    id_section: '4',
    section: 'Radio',
    section_alias: 'radio',
    section_icon: 'icon-radio1',
    reel: appBasePath + '/assets/images/reel_playlist.jpg',
    reelMobile: appBasePath + '/assets/images/reel_playlist_mobile.jpg'
  },
  {
    category: 'DJs',
    category_alias: 'djs',
    category_icon: '',
    id_category: '26',
    id_section: '4',
    section: 'Radio',
    section_alias: 'radio',
    section_icon: 'icon-radio1',
    reel: appBasePath + '/assets/images/reel_djs.jpg',
    reelMobile: appBasePath + '/assets/images/reel_djs_mobile.jpg'
  }
]
