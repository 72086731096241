// Layout Types
import DefaultLayout from './layouts/DefaulLayout'
import PlayerLayout from './layouts/PlayerLayout'

// Route Views
import Playlist from './pages/Playlist'
import Station from './pages/Station'

// Core Components
import Player from './components/core/Player'
import MinimalPlayer from './components/core/MinimalPlayer'

// Configurations
import { radioCategories } from './config/radioCategories'
import {
  urlPublicNames
} from './config/stations'

const _radioCategories = []
radioCategories.forEach((val, id) => {
  if (val.category_alias !== 'podcast') {
    _radioCategories.push(val.category_alias)
  }
})
const _stations = Object.keys(urlPublicNames)

export const routes = [
  {
    path: '/',
    layout: PlayerLayout,
    exact: true,
    component: Player
  },
  {
    path: '/playlist/:hashid([0-9]{1,10}_[a-zA-Z0-9-]{1,300})',
    layout: DefaultLayout,
    exact: true,
    component: Playlist
  },
  {
    path: '/station/:hashid([0-9]{1,10}_[a-zA-Z0-9-]{1,300})',
    layout: DefaultLayout,
    exact: true,
    component: Station
  },
  {
    path: `/:station(${_stations.join('|')})`,
    layout: PlayerLayout,
    exact: true,
    component: MinimalPlayer
  }
]
