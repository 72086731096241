import { http } from '../../http'
import { apiHeaders, apiBasePath } from '../../../config/api'

const servicePath = 'web/streaming'

export const getStreamResource = (requestParams, log) => {
  const url = `${apiBasePath}/${servicePath}`
  const data = requestParams || {}
  return new Promise((resolve, reject) => {
    http(url, 'get', data, apiHeaders)
      .then((response) => {
        const request = response.data
        if (request.status === false && log === true) {
          console.error(request.message)
        }
        resolve(request.data.resources)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getStreamStats = (url) => {
  const data = {}
  return new Promise((resolve, reject) => {
    http(url, 'get', data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        console.info('error', error)
        reject(error)
      })
  })
}
