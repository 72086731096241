import React, { useRef, useState, useEffect } from 'react'
import {
  IonButton,
  IonIcon
} from '@ionic/react'
import {
  close
} from 'ionicons/icons'
import './styles.scss'

const InstallableBanner = (props) => {
  const { message, callToAction } = props
  const [deferredPrompt, setDeferredPrompt] = useState(null)
  const ref = useRef()

  const hide = () => {
    ref.current.style.width = '0'
  }

  const show = () => {
    ref.current.style.width = '300px'
  }

  const install = (e) => {
    hide()
    deferredPrompt.prompt()
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('%c User accepted the A2HS prompt', 'color: #1f7a1f; font-weight: bold')
      } else {
        console.log('%c User dismissed the A2HS prompt', 'color: #990000; font-weight: bold')
      }
      setDeferredPrompt(null)
    })
  }

  useEffect(() => {
    if (document.querySelector('html').classList.contains('plt-desktop')) {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault()
        setDeferredPrompt(e)
        show()
      })
    }
  }, [])

  if (document.querySelector('html').classList.contains('plt-desktop') === false) return null

  return (
    <div
      className='installable-banner'
      ref={ref}
    >
      <div className='button-wrapper'>
        <IonButton
          className='close-button'
          aria-label='close banner'
          onClick={hide}
        >
          <IonIcon size='48' icon={close} />
        </IonButton>
      </div>
      <div className='action-wrapper'>
        <IonButton
          className='add-button'
          aria-label={callToAction || 'Aquí'}
          onClick={install}
        >
          {callToAction || 'Aquí'}
        </IonButton>
        <p ng-bind='vm.message'>{message || 'Anclá Yok Radio a tu escritorio, ¡en un solo click!'}</p>
      </div>
    </div>
  )
}

export default InstallableBanner
