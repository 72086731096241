import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  isPlatform
} from '@ionic/react'
import {
  setPlayerPlaying,
  setPlayerPause,
  playerPause,
  playerPlaying
} from '../../../../redux/actions'
import { Plugins } from '@capacitor/core'
import { setEvent } from '../../../../services/analytics'
const { CapacitorMusicControls } = Plugins

const MediaSession = () => {
  const track = useSelector(state => state.track)
  const nowPlaying = useSelector(state => state.nowPlaying)
  const sound = useSelector(state => state.sound)
  const playerStatus = useSelector(state => state.playerStatus)
  const [musicControls, setMusicControls] = useState(false)
  const dispatch = useDispatch()

  const _setEvent = (category, action, label) => {
    setEvent({
      category: category || 'Media Session',
      action: action || '-',
      label: label || track.id
    })
  }

  const play = () => {
    sound.play()
    dispatch(setPlayerPlaying())
    _setEvent('Media Session', 'Reanudar Reproducción', track.id)
  }

  const pause = () => {
    sound.pause()
    dispatch(setPlayerPause())
    _setEvent('Media Session', 'Pausar Reproducción', track.id)
  }

  const createMediaSession = () => {
    if ('mediaSession' in navigator) {
      navigator.mediaSession.metadata = new window.MediaMetadata({
        title: nowPlaying.title,
        artist: nowPlaying.artist,
        artwork: track.artwork
      })
      createMediaSessionHandler()
    }
  }

  const createMediaSessionHandler = () => {
    navigator.mediaSession.setActionHandler('play', function () {
      play()
    })
    navigator.mediaSession.setActionHandler('pause', function () {
      pause()
    })
    navigator.mediaSession.playbackState = 'none'
  }

  const createMusicControls = () => {
    if (isPlatform('cordova')) {
      if (musicControls) {
        CapacitorMusicControls.destroy()
      }
      CapacitorMusicControls.create({
        track: nowPlaying.title, // optional, default : ''
        artist: nowPlaying.artist, // optional, default : ''
        album: '',
        cover: track.artwork[track.artwork.length - 1].src, // optional, default : nothing
        // cover can be a local path (use fullpath 'file:///storage/emulated/...', or only 'my_image.jpg' if my_image.jpg is in the www folder of your app)
        // or a remote url ('http://...', 'https://...', 'ftp://...')

        // hide previous/next/close buttons:
        hasPrev: false, // show previous button, optional, default: true
        hasNext: false, // show next button, optional, default: true
        hasClose: false, // show close button, optional, default: false
        // iOS only, optional
        duration: 0, // optional, default: 0
        elapsed: 0, // optional, default: 0
        hasSkipForward: false, // optional, default: false. true value overrides hasNext.
        hasSkipBackward: false, // optional, default: false. true value overrides hasPrev.
        skipForwardInterval: 30, // optional. default: 15.
        skipBackwardInterval: 30, // optional. default: 15.
        hasScrubbing: false, // optional. default to false. Enable scrubbing from control center progress bar

        // Android only, optional
        isPlaying: playerStatus === playerPlaying, // optional, default : true
        dismissable: true, // optional, default : false
        // text displayed in the status bar when the notification (and the ticker) are updated
        ticker: `Estás escuchando ${nowPlaying.artist} - ${nowPlaying.title}`,
        // All icons default to their built-in android equivalents
        // The supplied drawable name, e.g. 'media_play', is the name of a drawable found under android/res/drawable* folders
        playIcon: 'play_icon',
        pauseIcon: 'pause_icon',
        prevIcon: 'media_prev',
        nextIcon: 'media_next',
        closeIcon: 'media_close',
        notificationIcon: 'radio_icon'
      },
      () => {},
      (err) => {
        console.info('Error creating music controls', err)
      })
      createMusicControlsHandler()
      setMusicControls(true)
    }
  }

  const createMusicControlsHandler = () => {
    if (isPlatform('android')) {
      CapacitorMusicControls.removeAllListeners()
    }
    CapacitorMusicControls.addListener('controlsNotification', handleControlsEvent)
  }

  const handleControlsEvent = (action) => {
    const message = action.message
    switch (message) {
      case 'music-controls-next':
        // Do something
        break
      case 'music-controls-previous':
        // Do something
        break
      case 'music-controls-pause':
        pause()
        break
      case 'music-controls-play':
        play()
        break
      case 'music-controls-destroy':
        // Do something
        break
        // External controls (iOS only)
      case 'music-controls-toggle-play-pause' :
        if (playerStatus === playerPlaying) {
          pause()
        }
        if (playerStatus === playerPause) {
          play()
        }
        break
      case 'music-controls-seek-to':
        /* const seekToInSeconds = JSON.parse(action).position
        MusicControls.updateElapsed({
          elapsed: seekToInSeconds,
          isPlaying: true
        }) */
        // Do something
        break
      case 'music-controls-skip-forward':
        // Do something
        break
      case 'music-controls-skip-backward':
        // Do something
        break
      // Headset events (Android only)
      // All media button events are listed below
      case 'music-controls-media-button' :
        if (playerStatus === playerPlaying) {
          pause()
        }
        if (playerStatus === playerPause) {
          play()
        }
        break
      case 'music-controls-headset-unplugged':
        if (playerStatus === playerPlaying) {
          pause()
        }
        break
      case 'music-controls-headset-plugged':
        // Do something
        break
      default:
        break
    }
  }

  useEffect(() => {
    if ('mediaSession' in navigator) {
      createMediaSessionHandler()
    }
    return () => {
      if (musicControls) {
        CapacitorMusicControls.destroy()
      }
    }
  }, [])

  useEffect(() => {
    if (track !== null && sound !== null) {
      createMediaSession()
      createMusicControls()
    }
  }, [nowPlaying, sound])

  useEffect(() => {
    if (playerStatus === playerPlaying) {
      if ('mediaSession' in navigator) {
        navigator.mediaSession.playbackState = 'playing'
      }
      if (isPlatform('cordova')) {
        try {
          CapacitorMusicControls.updateIsPlaying({
            isPlaying: true
          })
        } catch (e) {
          createMusicControls()
        }
      }
    }
    if (playerStatus === playerPause) {
      if ('mediaSession' in navigator) {
        navigator.mediaSession.playbackState = 'paused'
      }
      if (isPlatform('cordova')) {
        try {
          CapacitorMusicControls.updateIsPlaying({
            isPlaying: false
          })
        } catch (e) {
          createMusicControls()
        }
      }
    }
  }, [playerStatus])

  return (
    <></>
  )
}

export default MediaSession
