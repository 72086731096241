import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import './styles.scss'

const MemoizedText = React.memo(({ text, type }) => {
  const ref = useRef()

  useEffect(() => {
    ref.current.style.animation = 'none'
    if (type === 'artist') {
      if ((window.innerWidth <= 991 && text.length >= 32) || (window.innerWidth > 991 && text.length >= 51)) {
        ref.current.style.animation = 'floatText 15s infinite ease-out'
      }
    } else {
      if ((window.innerWidth <= 991 && text.length >= 27) || (window.innerWidth > 991 && text.length >= 36)) {
        ref.current.style.animation = 'floatText 15s infinite ease-out'
      }
    }
  }, [text, type])
  return (
    <p ref={ref} className={type}>
      {text || ''}
    </p>
  )
})

const NowPlaying = ({ message }) => {
  const track = useSelector(state => state.track)
  const nowPlaying = useSelector(state => state.nowPlaying)

  return (
    <div className='now-playing'>
      {track === null &&
        <p className='message'>
          {message || '¡Selecciona tu estación favorita!'}
        </p>}
      {track !== null &&
        <>
          <MemoizedText text={nowPlaying.title} type='title' />
          <MemoizedText text={nowPlaying.artist} type='artist' />
        </>}
    </div>
  )
}

export default NowPlaying
