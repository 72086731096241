export const SET_NOTIFICATION = 'SET_NOTIFICATION'

export const setNotification = (notification) => {
  return {
    type: SET_NOTIFICATION,
    notification: notification
  }
}

export const SET_SERVICE_WORKER_INIT = 'SET_SERVICE_WORKER_INIT'

export const setServiceWorkerInit = (registration) => {
  return {
    type: SET_SERVICE_WORKER_INIT,
    serviceWorkerInit: registration
  }
}

export const SET_SERVICE_WORKER_UPDATE = 'SET_SERVICE_WORKER_UPDATE'

export const setServiceWorkerUpdate = (registration) => {
  return {
    type: SET_SERVICE_WORKER_UPDATE,
    serviceWorkerUpdate: registration
  }
}

export const SET_GA = 'SET_GA'

export const setGa = (ga) => {
  return {
    type: SET_GA,
    ga: ga
  }
}

export const SET_PLAYER = 'SET_PLAYER'

export const setPlayer = (player) => {
  return {
    type: SET_PLAYER,
    player: player
  }
}

export const SET_TRACK = 'SET_TRACK'

export const setTrack = (track) => {
  return {
    type: SET_TRACK,
    track: track
  }
}

export const SET_DEFAULT_TRACK = 'SET_DEFAULT_TRACK'

export const setDefaultTrack = (defaultTrack) => {
  return {
    type: SET_DEFAULT_TRACK,
    defaultTrack: defaultTrack
  }
}

export const SET_TRACK_CURRENT_TIME = 'SET_TRACK_CURRENT_TIME'

export const setTrackCurrentTime = (trackCurrentTime) => {
  return {
    type: SET_TRACK_CURRENT_TIME,
    trackCurrentTime: trackCurrentTime
  }
}

export const SET_TRACK_TIME_LEFT = 'SET_TRACK_TIME_LEFT'

export const setTrackTimeLeft = (trackTimeLeft) => {
  return {
    type: SET_TRACK_TIME_LEFT,
    trackTimeLeft: trackTimeLeft
  }
}

export const SET_TRACK_PROGRESS = 'SET_TRACK_PROGRESS'

export const setTrackProgress = (trackProgress) => {
  return {
    type: SET_TRACK_PROGRESS,
    trackProgress: trackProgress
  }
}

export const SET_TRACK_DURATION = 'SET_TRACK_DURATION'

export const setTrackDuration = (trackDuration) => {
  return {
    type: SET_TRACK_DURATION,
    trackDuration: trackDuration
  }
}

export const SET_MUTED = 'SET_MUTED'

export const setMuted = (muted) => {
  return {
    type: SET_MUTED,
    muted: muted
  }
}

export const SET_PLAYING = 'SET_PLAYING'

export const setPlaying = (playing) => {
  return {
    type: SET_PLAYING,
    playing: playing
  }
}

export const SET_SOUND = 'SET_SOUND'

export const setSound = (sound) => {
  return {
    type: SET_SOUND,
    sound: sound
  }
}

export const SET_NOW_PLAYING = 'SET_NOW_PLAYING'

export const setNowPlaying = (nowPlaying) => {
  return {
    type: SET_NOW_PLAYING,
    nowPlaying: nowPlaying
  }
}

export const SET_PLAYER_STATUS = 'SET_PLAYER_STATUS'

export const setPlayerStatus = (playerStatus) => {
  return {
    type: SET_PLAYER_STATUS,
    playerStatus: playerStatus
  }
}

export const playerIdle = 'playerIdle'

export const playerLoading = 'playerLoading'

export const playerPlaying = 'playerPlaying'

export const playerPause = 'playerPause'

export const setPlayerIdle = () => {
  return setPlayerStatus(playerIdle)
}

export const setPlayerLoading = () => {
  return setPlayerStatus(playerLoading)
}

export const setPlayerPlaying = () => {
  return setPlayerStatus(playerPlaying)
}

export const setPlayerPause = () => {
  return setPlayerStatus(playerPause)
}

export const SET_YR_URBAN = 'SET_YR_URBAN'

export const setYrUrban = (station) => {
  return {
    type: SET_YR_URBAN,
    yr_urban: station
  }
}

export const SET_YR_CLASSICS = 'SET_YR_CLASSICS'

export const setYrClassics = (station) => {
  return {
    type: SET_YR_CLASSICS,
    yr_classics: station
  }
}

export const SET_YR_TROPICAL = 'SET_YR_TROPICAL'

export const setYrTropical = (station) => {
  return {
    type: SET_YR_TROPICAL,
    yr_tropical: station
  }
}

export const SET_YR_ROMANTIC = 'SET_YR_ROMANTIC'

export const setYrRomantic = (station) => {
  return {
    type: SET_YR_ROMANTIC,
    yr_romantic: station
  }
}

export const SET_YR_LIVE = 'SET_YR_LIVE'

export const setYrLive = (station) => {
  return {
    type: SET_YR_LIVE,
    yr_live: station
  }
}

export const SET_LIVE_VIDEO = 'SET_LIVE_VIDEO'

export const setLiveVideo = (liveVideo) => {
  return {
    type: SET_LIVE_VIDEO,
    liveVideo: liveVideo
  }
}

export const SET_REQUESTING_STREAMS = 'SET_REQUESTING_STREAMS'

export const setRequestingStreams = (requestingStreams) => {
  return {
    type: SET_REQUESTING_STREAMS,
    requestingStreams: requestingStreams
  }
}

export const SET_SHOW_RETURN_BUTTON = 'SET_SHOW_RETURN_BUTTON'

export const setShowReturnButton = (showReturnButton) => {
  return {
    type: SET_SHOW_RETURN_BUTTON,
    showReturnButton: showReturnButton
  }
}
