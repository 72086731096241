import galite from 'ga-lite'
import { gaTagId } from '../config/app'

export const initializeAnalytics = () => {
  galite('create', gaTagId, 'auto')
}

export const setView = (path) => {
  galite('send', 'pageview', path)
}

export const setEvent = (args) => {
  const { category, action, label, value } = args
  if (typeof category === 'undefined' || typeof action === 'undefined') {
    return false
  }
  const eventArgs = {
    eventCategory: category,
    eventAction: action
  }
  if (typeof label !== 'undefined') {
    eventArgs.eventLabel = label
  }
  if (typeof value !== 'undefined') {
    eventArgs.eventValue = value
  }
  galite('send', 'event', eventArgs)
}
