import { defineCustomElements } from '@ionic/pwa-elements/loader'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane
} from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import InstallableBanner from './components/core/InstallableBanner'
import NetworkStatus from './components/core/NetworkStatus'
import Navbar from './components/core/Navbar'
import MediaSession from './components/core/player-parts/MediaSession/index'
import BackgroundUsage from './components/core/player-parts/BackgroundUsage'
import NowPlayingUpdater from './components/core/player-parts/NowPlayingUpdater'
import PlaybackBarUpdater from './components/core/player-parts/PlaybackBarUpdater'
import StreamUpdater from './components/core/player-parts/StreamsUpdater'
import { routes } from './routes'
import { soundManager } from 'soundmanager2'
import {
  setPlayer
} from './redux/actions'
import { initializeAnalytics } from './services/analytics'
import { initializeFbPixel } from './services/fbPixel'

import './assets/fonts/Poppins/style.css'
import 'luminous-lightbox/dist/luminous-basic.min.css'
import 'simplebar/dist/simplebar.min.css'
import './App.scss'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'

defineCustomElements(window)

const App = () => {
  const player = useSelector(state => state.player)
  const dispatch = useDispatch()

  useEffect(() => {
    if (player === null) {
      soundManager.setup({
        url: `${process.env.PUBLIC_URL}/assets/swf`, // path (directory) where SoundManager 2 SWFs exist, eg., /path/to/swfs/
        flashVersion: 8, // flash build to use (8 or 9.) Some API features require 9.
        debugMode: (process.env.NODE_ENV !== 'production'), // enable debugging output (console.log() with HTML fallback)
        debugFlash: false, // enable debugging output inside SWF, troubleshoot Flash/browser issues
        useConsole: (process.env.NODE_ENV !== 'production'), // use console.log() if available (otherwise, writes to #soundmanager-debug element)
        consoleOnly: true, // if console is being used, do not create/write to #soundmanager-debug
        waitForWindowLoad: false, // force SM2 to wait for window.onload() before trying to call soundManager.onload()
        bgColor: '#ffffff', // SWF background color. N/A when wmode = transparent
        useHighPerformance: false, // position:fixed flash movie can help increase js/flash speed, minimize lag
        flashPollingInterval: null, // msec affecting whileplaying/loading callback frequency. If null, default of 50 msec is used.
        html5PollingInterval: null, // msec affecting whileplaying() for HTML5 audio, excluding mobile devices. If null, native HTML5 update events are used.
        flashLoadTimeout: 1000, // msec to wait for flash movie to load before failing (0 = infinity)
        wmode: null, // flash rendering mode - null, 'transparent', or 'opaque' (last two allow z-index to work)
        allowScriptAccess: 'always', // for scripting the SWF (object/embed property), 'always' or 'sameDomain'
        useFlashBlock: false, // *requires flashblock.css, see demos* - allow recovery from flash blockers. Wait indefinitely and apply timeout CSS to SWF, if applicable.
        useHTML5Audio: true, // use HTML5 Audio() where API is supported (most Safari, Chrome versions), Firefox (no MP3/MP4.) Ideally, transparent vs. Flash API where possible.
        html5Test: /^(probably|maybe)$/i, // HTML5 Audio() format support test. Use /^probably$/i; if you want to be more conservative.
        preferFlash: false, // overrides useHTML5audio, will use Flash for MP3/MP4/AAC if present. Potential option if HTML5 playback with these formats is quirky.
        noSWFCache: false, // if true, appends ?ts={date} to break aggressive SWF caching.
        idPrefix: 'sound', // if an id is not provided to createSound(), this prefix is used for generated IDs - 'sound0', 'sound1' etc.
        forceUseGlobalHTML5Audio: true // if true, a single Audio() object is used for all sounds - and only one can play at a time.
      },
      {
        autoLoad: true, // enable automatic loading (otherwise .load() will be called on demand with .play(), the latter being nicer on bandwidth - if you want to .load yourself, you also can)
        autoPlay: false, // enable playing of file as soon as possible (much faster if "stream" is true)
        from: null, // position to start playback within a sound (msec), default = beginning
        loops: 1, // how many times to repeat the sound (position will wrap around to 0, setPosition() will break out of loop when >0)
        onid3: null, // callback function for "ID3 data is added/available"
        onload: null, // callback function for "load finished"
        whileloading: null, // callback function for "download progress update" (X of Y bytes received)
        onplay: null, // callback for "play" start
        onpause: null, // callback for "pause"
        onresume: null, // callback for "resume" (pause toggle)
        whileplaying: null, // callback during play (position update)
        onposition: null, // object containing times and function callbacks for positions of interest
        onstop: null, // callback for "user stop"
        onfailure: null, // callback function for when playing fails
        onfinish: null, // callback function for "sound finished playing"
        multiShot: false, // let sounds "restart" or layer on top of each other when played multiple times, rather than one-shot/one at a time
        multiShotEvents: false, // fire multiple sound events (currently onfinish() only) when multiShot is enabled
        position: null, // offset (milliseconds) to seek to within loaded sound data.
        pan: 0, // "pan" settings, left-to-right, -100 to 100
        stream: true, // allows playing before entire file has loaded (recommended)
        to: null, // position to end playback within a sound (msec), default = end
        type: null, // MIME-like hint for file pattern / canPlay() tests, eg. audio/mp3
        usePolicyFile: true, // enable crossdomain.xml request for audio on remote domains (for ID3/waveform access)
        volume: 100 // self-explanatory. 0-100, the latter being the max.
      })
      dispatch(setPlayer(soundManager))
    }
    initializeAnalytics()
    initializeFbPixel()
  }, [])

  return (
    <IonApp>
      <InstallableBanner />
      <NetworkStatus />
      <MediaSession />
      <BackgroundUsage />
      <PlaybackBarUpdater />
      <NowPlayingUpdater />
      <StreamUpdater />
      <IonReactRouter>
        <IonSplitPane contentId='main' when='false'>
          <Navbar />
          <IonRouterOutlet id='main'>
            {routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={(...props) => (
                    <route.layout {...props}>
                      <route.component {...props} />
                    </route.layout>
                  )}
                />
              )
            })}
            <Redirect to='/404' />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  )
}

export default App
