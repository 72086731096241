import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  isPlatform
} from '@ionic/react'
import { BackgroundMode } from '@ionic-native/background-mode'
import {
  playerPlaying,
  playerLoading
} from '../../../../redux/actions'

const BackgroundUsage = () => {
  const playerStatus = useSelector(state => state.playerStatus)

  useEffect(() => {
    if (isPlatform('android')) {
      if (playerStatus === playerPlaying || playerStatus === playerLoading) {
        if (!BackgroundMode.isEnabled()) {
          BackgroundMode.enable()
          BackgroundMode.disableBatteryOptimizations()
          /* console.log('%cEnable background Mode', 'color: green; font-weight: 700; font-size:24px') */
        }
      } else {
        if (BackgroundMode.isEnabled()) {
          BackgroundMode.disable()
          /* console.log('%cDisable background Mode', 'color: red; font-weight: 700; font-size:24px') */
        }
      }
    }
  }, [playerStatus])
  return (<></>)
}

export default BackgroundUsage
