import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  setPlayerLoading,
  setPlayerPlaying,
  setPlayerPause,
  setSound,
  playerIdle,
  playerLoading,
  playerPause,
  playerPlaying,
  setTrack
} from '../../../../redux/actions'
import {
  IonButton,
  IonSpinner
} from '@ionic/react'
import { defaultStation } from '../../../../config/stations'
import YokIcon from '../../../common/YokIcon'
import { setEvent } from '../../../../services/analytics'
import './styles.scss'

const PlayButton = ({ station, skipDefault }) => {
  const stationState = useSelector(state => state[defaultStation.stream_id])
  const playerStatus = useSelector(state => state.playerStatus)
  const requestingStreams = useSelector(state => state.requestingStreams)
  const sound = useSelector(state => state.sound)
  const track = useSelector(state => state.track)
  const player = useSelector(state => state.player)
  const muted = useSelector(state => state.muted)
  const dispatch = useDispatch()

  const _setEvent = (category, action, label) => {
    setEvent({
      category: category || 'Botón de Maestro de Reproducción',
      action: action || '-',
      label: label || track.public_name
    })
  }

  const isBuffering = (buffering) => {
    if (buffering === 1) {
      dispatch(setPlayerLoading())
    } else {
      dispatch(setPlayerPlaying())
    }
  }

  const onDataError = (error) => {
    console.info('Error on data', error)
  }

  const onError = (errorCode, description) => {
    console.info('Error playing Error code:' + errorCode)
    console.info('Error playing Error message:', description)
  }

  const playDefault = () => {
    const _station = station || stationState
    const _sound = player.createSound({
      id: _station.id,
      url: _station.src,
      volume: 100,
      multiShot: false,
      playbackRate: 1,
      stream: true,
      onbufferchange: isBuffering,
      ondataerror: onDataError,
      onerror: onError
    })
    if (muted) {
      _sound.mute()
    }
    dispatch(setSound(_sound))
    dispatch(setPlayerPlaying())
    dispatch(setTrack(_station))
    _sound.play()
    _setEvent('Botón de Maestro de Reproducción', 'Reproducir Estación', _station.public_name)
  }

  const play = () => {
    sound.play()
    dispatch(setPlayerPlaying())
    _setEvent('Botón de Maestro de Reproducción', 'Reanudar Reproducción', track.id)
  }

  const pause = () => {
    sound.pause()
    dispatch(setPlayerPause())
    _setEvent('Botón de Maestro de Reproducción', 'Pausar Reproducción', track.id)
  }

  if (requestingStreams === true) {
    return (
      <div className='player-play-button'>
        <IonButton>
          <IonSpinner name='crescent' />
        </IonButton>
      </div>
    )
  }

  if (playerStatus === playerIdle && skipDefault) return null

  if (playerStatus === playerIdle) {
    return (
      <>
        {stationState?.status === 'on-line' &&
          <div className='player-play-button'>
            <IonButton className='play-button' onClick={playDefault}>
              <YokIcon name='ra-play' />
            </IonButton>
          </div>}
        {stationState?.status !== 'on-line' &&
          <></>}
      </>
    )
  } else {
    return (
      <div className='player-play-button'>
        {playerStatus === playerPlaying &&
          <IonButton onClick={pause}>
            <YokIcon name='ra-pause' />
          </IonButton>}
        {playerStatus === playerPause &&
          <IonButton onClick={play}>
            <YokIcon name='ra-play' />
          </IonButton>}
        {playerStatus === playerLoading &&
          <IonButton>
            <IonSpinner name='crescent' />
          </IonButton>}
      </div>
    )
  }
}

export default PlayButton
