import axios from 'axios'
import { HTTP } from '@ionic-native/http'
import { isPlatform } from '@ionic/react'
import { appId, corsProxy } from '../config/app'
import { apiBasePath } from '../config/api'

export const http = (...args) => {
  const [url, method, data, headers] = args
  if (url.includes(apiBasePath)) {
    data.app_id = typeof data.app_id === 'undefined' ? appId : data.app_id
    data.platform_id = typeof data.platform_id === 'undefined' ? appId : data.platform_id
  }
  /* const requestUrl = (process.env.NODE_ENV === 'production' ? '' : corsProxy) + ((method === 'get' || method === 'delete') ? paramsToUrl(url, data) : url) */
  const requestUrl = (method === 'get' || method === 'delete') ? paramsToUrl(url, data) : url

  if (isPlatform('cordova')) {
    const options = {
      method: method
    }
    if (method === 'post' || method === 'put') {
      options.data = data
    }
    if (typeof headers !== 'undefined') {
      options.headers = headers
    }
    return new Promise((resolve, reject) => {
      HTTP
        .sendRequest(requestUrl, options)
        .then((response) => {
          resolve(handleSuccesfulCall(response))
        })
        .catch((error) => {
          reject(handleFailedCall(error))
        })
    })
  } else {
    const options = {
      method: method,
      url: requestUrl
    }
    if (method === 'post' || method === 'put') {
      options.data = data
    }
    if (typeof headers !== 'undefined') {
      options.headers = headers
    }
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(handleSuccesfulCall(response))
        })
        .catch((error) => {
          reject(handleFailedCall(error))
        })
    })
  }
}

const paramsToUrl = (...args) => {
  const [url, data] = args
  if (Object.keys(data).length > 0) {
    var newUrl = `${url}?`
    Object.keys(data).forEach((value, id) => {
      newUrl += `${value}=${Array.isArray(data[value]) ? data[value].join(',') : data[value]}&`
    })
    return newUrl
  } else {
    return url
  }
}

const handleSuccesfulCall = (response) => {
  if (typeof response.data === 'string') {
    response.data = JSON.parse(response.data)
  }
  return response
}

const handleFailedCall = (error) => {
  console.error(`Request error: ${error.message}`)
  return error
}

export const parseValue = function (type, value) {
  var toReturn
  switch (type) {
    case 'int':
      toReturn = parseInt(value)
      break
    case 'float':
      toReturn = parseFloat(value)
      break
    case 'boolean':
      if (typeof value === 'boolean') {
        toReturn = value
      } else {
        toReturn = (String(value).toLowerCase() === 'true')
      }
      break
    default:
      toReturn = String(value).toLowerCase()
      break
  }
  return toReturn
}

export const filterParams = function (requestParams, validParams) {
  var params = {}
  var keys = Object.keys(validParams)

  if (typeof requestParams !== 'object') {
    return {}
  }

  for (var i = 0; i < keys.length; i++) {
    const isDefined = typeof requestParams[keys[i]] !== 'undefined'
    const value = validParams[keys[i]]

    /**
           * Is not defined, but required
           */
    if (isDefined === false && value.required === true) {
      params = false
      break
    }

    /**
           * Is not defined, but have a default value
           */
    if (isDefined === false && 'default' in value === true) {
      params[[keys[i]]] = parseValue(value.type, value.default)
    }

    /**
           * Is defined, is array
           */
    if (isDefined === true && value.array === true) {
      if (Array.isArray(requestParams[keys[i]]) === true) {
        var temp = requestParams[keys[i]]
        for (let j = 0; j < temp.length; j++) {
          temp[j] = parseValue(value.type, temp[j])
        }
        params[keys[i]] = temp.join(',')
      } else {
        params[keys[i]] = parseValue(value.type, requestParams[keys[i]])
      }
    }

    /**
           * Is defined, is not array
           */
    if (isDefined === true && value.array === false) {
      if (Array.isArray(requestParams[keys[i]]) === false) {
        params[keys[i]] = parseValue(value.type, requestParams[keys[i]])
      }
    }
  }

  return params
}
