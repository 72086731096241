import React, { useEffect, useState, useRef } from 'react'
import parse from 'html-react-parser'
import { useParams, useHistory } from 'react-router-dom'
import {
  IonContent,
  useIonViewDidEnter,
  useIonViewWillLeave,
  useIonToast,
  IonImg
} from '@ionic/react'
import PlaylistPlayer from './PlaylistPlayer'
import ContentButton from '../../components/core/player-parts/ContentButton'
import { setMetaTags } from '../../services/metatags'
import { setView } from '../../services/analytics'
import {
  getPlaylist
} from '../../services/api/playlistService'
import './styles.scss'

const PlaylistButton = (args) => {
  const { file, account } = args

  if (typeof file === 'undefined' || typeof account === 'undefined') return null

  const chapter = {
    id: file.id,
    title: file.title,
    url_title: file.url_title,
    intro: file.intro,
    id_media_file_type: 1,
    media_file_type: 'audio',
    audio_file: file.audio_file,
    audio_file_type: file.audio_file_type,
    photo: account.photo || '',
    content_type: 'playlist',
    windable: true
  }
  return (
    <div className='button-container'>
      <ContentButton content={chapter} />
    </div>
  )
}

const Playlist = (props) => {
  const [present] = useIonToast()
  const [isVisible, setIsVisible] = useState(false)
  const [playlist, setPlaylist] = useState(false)
  const { hashid } = useParams()
  const [id, title] = hashid.split('_')
  const contentRef = useRef()
  const history = useHistory()

  useIonViewDidEnter(() => {
    setIsVisible(true)
    setView(window.location.pathname)
  })

  useIonViewWillLeave(() => {
    setIsVisible(false)
  })

  useEffect(() => {
    const params = {
      id: id,
      title: title
    }
    getPlaylist(params, true)
      .then((response) => {
        setPlaylist(response)
        setView(window.location.pathname)
        setMetaTags({
          title: `${response.account.sponsor} - Playlist - Yok Radio`
        })
        console.info('response', response)
      })
      .catch((e) => {
        setPlaylist(false)
        present({
          message: e.response.data.message || 'Se produjo un error con la cosulta.',
          duration: 3000,
          position: 'bottom',
          color: 'danger'
        })
        history.push('/')
      })
    return () => {
      setPlaylist(false)
    }
  }, [id, title])

  if (isVisible === false) return null

  return (
    <IonContent
      ref={contentRef}
      fullscreen
      className='playlist-page page-wrapper'
    >
      <div className='content-wrapper'>
        {playlist?.account &&
          <div className='slideshow'>
            <figure itemProp='image'>
              <IonImg
                src={playlist.account.photo}
                alt={`Portada del episodio ${playlist.account.sponsor}`}
              />
            </figure>
            <figcaption>
              <h1 className='title'>
                Playlists - {playlist.account.sponsor}
              </h1>
              {playlist?.files &&
                <section>
                  {playlist.files.length === 0 &&
                    <p className='intro'>La cuenta no posee ningun playlist asignado aún. Vuelve pronto.</p>}
                  {playlist.files.length > 0 && playlist.files.map((file) => (
                    <div key={file.id} className='file-container'>
                      {file?.title &&
                        <h2 className='subtitle'>
                          {file.title}
                        </h2>}
                      {file?.intro &&
                        <div className='intro'>
                          {parse(file.intro)}
                        </div>}
                      <PlaylistButton file={file} account={playlist.account} />
                    </div>
                  ))}
                </section>}
            </figcaption>
          </div>}
        <PlaylistPlayer />
      </div>
    </IonContent>
  )
}
export default Playlist
