export const rootSettings = {
  site: 'Yok Radio',
  author: 'Yok Radio',
  ogType: 'website',
  title: 'Yok Radio - Últimas noticias en español de música y Podcast en español',
  description: 'En Yok Radio te ofrecemos 4 estaciones de radio, las últimas noticias de la música, cines y series y los mejores shows musicales y Podcast.',
  keywords: 'noticias de música en español, noticias de series en español, noticias de cine en español, podcast en español, shows musicales, estación de radio, radio en vivo, radio online',
  image: 'https://yokradio.com/assets/images/preview_card_v3.jpg',
  image_width: '1024',
  image_height: '1024'
}

export const newsSettings = {
  ogType: 'website',
  title: 'Últimas noticias en español de música, cines y series - Yok Radio ',
  description: 'Lee las últimas noticias en español de música, cines y series que están en tendencia.',
  keywords: 'noticias de música en español, noticias de series en español, noticias de cine en español',
  image: 'https://yokradio.com/assets/images/preview_card_v3.jpg',
  image_width: '1024',
  image_height: '1024',
  section: {
    alias: 'news',
    id: '3'
  },
  navigation: {
    rows: 12
  }
}

export const radioSettings = {
  ogType: 'website',
  title: 'Podcast en español y Shows Musicales - Yok Radio',
  description: 'Disfruta de los mejores shows músicales y podcast español producidos por Yok Radio.',
  keywords: 'videos de,multimedia,cabina de radio',
  image: 'https://yokradio.com/assets/images/preview_card_v3.jpg',
  image_width: '1024',
  image_height: '1024',
  section: {
    alias: 'radio',
    id: '4'
  },
  navigation: {
    rows: 12
  }
}

export const topSettings = {
  ogType: 'website',
  title: 'Tops de música - Yok Radio ',
  description: 'Disfruta de tops musicales con canciones seleccionadas y mezcladas en Yok Radio',
  keywords: 'radio, tops, mezclas, tops musicales, mezclas musicales',
  image: 'https://yokradio.com/assets/images/preview_card_v3.jpg',
  image_width: '1024',
  image_height: '1024',
  section: {
    alias: 'tops'
  },
  navigation: {
    rows: 12
  }
}

export const searchSettings = {
  limit: 20
}
