import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  IonContent,
  IonGrid,
  IonRow,
  IonButton,
  useIonViewDidEnter,
  useIonViewWillLeave

} from '@ionic/react'
import MuteButton from '../player-parts/MuteButton'
import PlayButton from '../player-parts/PlayButton'
import NowPlaying from '../player-parts/NowPlaying'
import YokIcon from '../../common/YokIcon'
import {
  urlPublicNames
} from '../../../config/stations'
import { setRootMetaTags } from '../../../services/metatags'
import './styles.scss'

const MinimalPlayer = () => {
  const { station } = useParams()
  const [isVisible, setIsVisible] = useState(false)
  const state = useSelector(state => state)
  const stationState = state[urlPublicNames[station]]
  const onLineStatus = 'on-line'

  useIonViewDidEnter(() => {
    setIsVisible(true)
    setRootMetaTags({
      title: `Disfruta de la mejor música de ${stationState.public_name} - Reproductor de Radio Online`,
      description: stationState.description
    })
  })

  useIonViewWillLeave(() => {
    setIsVisible(false)
  })

  if (isVisible === false) return null

  return (
    <IonContent
      fullscreen
    >
      <div
        className='minimal-player-wrapper'
      >

        <div className='minimal-player'>
          {stationState.status === onLineStatus &&
            <div className='wrapper'>
              <MuteButton />
              <PlayButton station={stationState} />
              <IonGrid>
                <IonRow>
                  <NowPlaying message={`Haz click para comenzar a escuchar ${stationState?.public_name}`} />
                </IonRow>
                <IonRow>
                  <div className='identify'>
                    <p><YokIcon name={stationState?.icon_on?.replace('icon-', '')} />{stationState?.public_name}</p>
                  </div>
                </IonRow>
              </IonGrid>
            </div>}
          {stationState.status !== onLineStatus &&
            <div className='wrapper offline'>
              <MuteButton />
              <div className='player-play-button'>
                <IonButton>
                  <YokIcon name='ra-play' />
                </IonButton>
              </div>
              <IonGrid>
                <IonRow>
                  <div className='out-of-line'>
                    <p>Estación Fuera de Línea</p>
                  </div>
                </IonRow>
                <IonRow>
                  <div className='identify'>
                    <p><YokIcon name={stationState?.icon_off?.replace('icon-', '')} />{stationState?.public_name}</p>
                  </div>
                </IonRow>
              </IonGrid>
            </div>}
        </div>

      </div>
    </IonContent>
  )
}

export default MinimalPlayer
