import React, { useEffect, useState, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
  IonContent,
  useIonViewDidEnter,
  useIonViewWillLeave,
  useIonToast,
  IonImg
} from '@ionic/react'
import StationPlayer from './StationPlayer'
import ContentButton from '../../components/core/player-parts/ContentButton'
import { setMetaTags } from '../../services/metatags'
import { setView } from '../../services/analytics'
import {
  getStation
} from '../../services/api/stationService'
import './styles.scss'

const CustomStationButton = (args) => {
  const { station } = args

  if (typeof station === 'undefined') return null

  const _station = {
    id: station.id,
    title: station.sponsor,
    url_title: station.sponsor_url,
    intro: station.intro,
    id_media_station_type: 1,
    media_station_type: 'audio',
    audio_file: station.source_url,
    audio_file_type: '',
    stats_url: station.stats_url,
    photo: station.photo || '',
    content_type: 'Radio en Linea',
    windable: false
  }
  return (
    <ContentButton content={_station} />
  )
}

const Station = (props) => {
  const [present] = useIonToast()
  const [isVisible, setIsVisible] = useState(false)
  const [station, setStation] = useState(false)
  const { hashid } = useParams()
  const [id, title] = hashid.split('_')
  const contentRef = useRef()
  const history = useHistory()

  useIonViewDidEnter(() => {
    setIsVisible(true)
    setView(window.location.pathname)
  })

  useIonViewWillLeave(() => {
    setIsVisible(false)
  })

  useEffect(() => {
    const params = {
      id: id,
      title: title
    }
    getStation(params, true)
      .then((response) => {
        setStation(response)
        setView(window.location.pathname)
        setMetaTags({
          title: `Estación de Radio de ${response.sponsor}`
        })
      })
      .catch((e) => {
        setStation(false)
        present({
          message: e.response.data.message || 'Se produjo un error con la cosulta.',
          duration: 3000,
          position: 'bottom',
          color: 'danger'
        })
        history.push('/')
      })
    return () => {
      setStation(false)
    }
  }, [id, title])

  if (isVisible === false) return null

  return (
    <IonContent
      ref={contentRef}
      fullscreen
      className='station-page page-wrapper'
    >

      <div className='content-wrapper'>
        {station &&
          <div className='slideshow'>
            <figure itemProp='image'>
              <IonImg
                src={station.photo}
                alt={`Portada del episodio ${station.sponsor}`}
              />
            </figure>
            <figcaption>
              <h1 className='title'>
                Radio - {station.sponsor}
              </h1>
              <section className='action-buttons'>
                <CustomStationButton station={station} />
              </section>
            </figcaption>
          </div>}
        <StationPlayer />
      </div>
    </IonContent>
  )
}
export default Station
