import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  setYrUrban,
  setYrClassics,
  setYrTropical,
  setYrRomantic,
  setYrLive,
  setLiveVideo,
  setRequestingStreams
} from '../../../../redux/actions'
import { stations } from '../../../../config/stations'
import { liveVideo, liveVideoId } from '../../../../config/live'
import { getStreamResource } from '../../../../services/api/streaming'

const StreamsUpdater = () => {
  const dispatch = useDispatch()
  const _stations = Object.keys(stations).join(',')

  useEffect(() => {
    var interval
    const getLiveResources = () => {
      dispatch(setRequestingStreams(true))
      const params = {
        stream_id: `${_stations},${liveVideoId}`
      }
      getStreamResource(params)
        .then((response) => {
          response.forEach((res) => {
            switch (res.stream_id) {
              case 'yr_urban':
                dispatch(setYrUrban(Object.assign(stations.yr_urban, res)))
                break
              case 'yr_classics':
                dispatch(setYrClassics(Object.assign(stations.yr_classics, res)))
                break
              case 'yr_tropical':
                dispatch(setYrTropical(Object.assign(stations.yr_tropical, res)))
                break
              case 'yr_romantic':
                dispatch(setYrRomantic(Object.assign(stations.yr_romantic, res)))
                break
              case 'yr_live':
                dispatch(setYrLive(Object.assign(stations.yr_live, res)))
                break
              case 'radio-live-video':
                dispatch(setLiveVideo(Object.assign(liveVideo, res)))
                break
              default:
                return res
            }
          })
          dispatch(setRequestingStreams(false))
        })
        .catch(() => {
          dispatch(setRequestingStreams(false))
          return false
        })
    }
    getLiveResources()
    interval = setInterval(getLiveResources, 300000)
    return () => {
      if (typeof interval !== 'undefined') {
        clearInterval(interval)
      }
    }
  }, [])

  return (<></>)
}

export default StreamsUpdater
