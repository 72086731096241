import React, { useEffect } from 'react'
import {
  useSelector,
  useDispatch
} from 'react-redux'
import {
  playerPlaying,
  setTrackCurrentTime,
  setTrackTimeLeft,
  setTrackDuration,
  setTrackProgress
} from '../../../../redux/actions'

var interval

const PlaybackBarUpdater = () => {
  const track = useSelector(state => state.track)
  const sound = useSelector(state => state.sound)
  const playerStatus = useSelector(state => state.playerStatus)
  const dispatch = useDispatch()

  const setTimer = () => {
    dispatch(setTrackProgress(sound.position))
    dispatch(setTrackDuration(sound.duration))

    const currentMinutes = Math.floor((sound.position / 1000) / 60)
    const currentSeconts = Math.floor((sound.position / 1000) - currentMinutes * 60)
    const leftMinutes = Math.floor((((sound.duration / 1000) || 0) - (sound.position / 1000)) / 60)
    const leftSeconds = Math.floor((((sound.duration / 1000) || 0) - (sound.position / 1000)) - leftMinutes * 60)
    let currentMinuteValue
    let currentSecondValue
    let leftMinuteValue
    let leftSecondValue

    if (currentMinutes < 10) {
      currentMinuteValue = '0' + currentMinutes
    } else {
      currentMinuteValue = currentMinutes
    }

    if (currentSeconts < 10) {
      currentSecondValue = '0' + currentSeconts
    } else {
      currentSecondValue = currentSeconts
    }

    if (leftMinutes < 10) {
      leftMinuteValue = '0' + leftMinutes
    } else {
      leftMinuteValue = leftMinutes
    }

    if (leftSeconds < 10) {
      leftSecondValue = '0' + leftSeconds
    } else {
      leftSecondValue = leftSeconds
    }

    dispatch(setTrackCurrentTime(currentMinuteValue + ':' + currentSecondValue))
    dispatch(setTrackTimeLeft(leftMinuteValue + ':' + leftSecondValue))
  }

  const startWatchingProgress = () => {
    stopWatchingProgress()
    interval = setInterval(() => {
      if (typeof sound.duration === 'number') {
        setTimer()
      }
    }, 1000)
  }

  const stopWatchingProgress = () => {
    if (typeof interval !== 'undefined') {
      clearInterval(interval)
    }
  }

  useEffect(() => {
    if (sound !== null) {
      stopWatchingProgress()
      setTimer()
    }
  }, [sound])

  useEffect(() => {
    if (playerStatus === playerPlaying && track.windable !== false) {
      startWatchingProgress()
    } else {
      stopWatchingProgress()
    }
    return () => {
      stopWatchingProgress()
    }
  }, [playerStatus])

  return <></>
}

export default PlaybackBarUpdater
