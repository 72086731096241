import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  IonButtons,
  IonButton,
  useIonViewDidEnter
} from '@ionic/react'
import YokIcon from '../../common/YokIcon'
import { setShowReturnButton } from '../../../redux/actions'

const ReturnButton = () => {
  const show = useSelector(state => state.showReturnButton)
  const dispatch = useDispatch()
  const history = useHistory()

  useIonViewDidEnter(() => {
    if (document.querySelectorAll('div.ion-page.can-go-back').length > 0) {
      dispatch(setShowReturnButton(true))
    }
  })

  const goBack = () => {
    history.goBack()
  }

  if (show === false) return null

  return (
    <IonButtons slot='start'>
      <IonButton
        className='return-button'
        onClick={goBack}
        aria-label='Regresar a página anterior'
      >
        <YokIcon name='xp-arrow-right' />
      </IonButton>
    </IonButtons>
  )
}

export default ReturnButton
