import { http, filterParams } from '../../http'
import { apiHeaders, apiBasePath } from '../../../config/api'

const servicePath = 'radio/services/station'

const stationParams = {
  id: {
    type: 'int',
    array: false
  },
  title: {
    type: 'string',
    array: false
  },
  password: {
    type: 'string',
    array: false
  }
}

export const getStation = (requestParams, log) => {
  const url = `${apiBasePath}/${servicePath}`
  const data = filterParams(requestParams, stationParams)
  return new Promise((resolve, reject) => {
    http(url, 'get', data, apiHeaders)
      .then((response) => {
        const request = response.data
        if (request.status === false && log === true) {
          console.error(request.message)
        }
        resolve(request.data.station)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
