import React from 'react'
import { IonPage } from '@ionic/react'
import Header from '../components/core/Header'

const DefaultLayout = ({ children }) => {
  return (
    <IonPage>
      <Header />
      {children}
    </IonPage>
  )
}

export default DefaultLayout
